#Sponsors {
    position: relative;
    background-image: url("../img/sponsors.svg");
    background-size: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding-bottom: 15vw;
    h2 {
        text-align: right;
        font-size: 2vw;
    }
    .container {
        flex-wrap: nowrap;
        padding-top: 10vw;
        font-size: 1.5em;
    }
    .btn-sponsor {
        margin-right: auto;
        margin-left: auto;
        padding: 10px;
        margin-top: 5vw;
        margin-bottom: 10vw;
        text-align: center;
        max-width: 100%;
    }
    img {
        margin-top: -5vw;
        width: 100%;
    }
}