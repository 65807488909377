.justify {
    text-align: justify;
}

.italic {
    font-style: italic;
}

$sizes: (10, 20, 30, 40, 50, 60, 70, 80, 100, 150, 200);
$directions: ('top', 'bottom', 'left', 'right');
@each $size in $sizes {
    @each $direction in $directions {
        .pad-#{$direction}-#{$size} {
            padding-#{$direction}: #{$size}px;
        }
    }
}

$directions: ('top', 'bottom', 'left', 'right');
@each $size in $sizes {
    @each $direction in $directions {
        .margin-#{$direction}-#{$size} {
            margin-#{$direction}: #{$size}px;
        }
    }
}