#partners {
    width: 70%;
    margin: auto;
    h2 {
        text-align: center;
        font-size: 2em;
        font-weight: 600;
        padding-bottom: 50px;
    }
    .active-partners {
        $col-width: calc(100% / 6);
        display: grid;
        grid-template: "mlh mlh github github uottawa uottawa" / $col-width $col-width $col-width $col-width $col-width $col-width;
        margin-bottom: 100px;
        .partner {
            text-align: center;
            display: flex;
            place-items: center;
            place-content: center;
            img {
                margin-bottom: 20px;
            }
        }
        .mlh {
            grid-area: mlh;
            img {
                width: 35%;
            }
        }
        .github {
            grid-area: github;
            img {
                width: 45%;
            }
        }
        .uottawa {
            grid-area: uottawa;
            img {
                width: 55%;
            }
        }
    }
}
