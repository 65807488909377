#Location {
    margin-bottom: 100px;
    position: relative;
    h2 {
        text-align: center;
        font-size: 2em;
        font-weight: 600;
        padding-bottom: 50px;
    }
    #map {
        width: 100%;
        border: none;
        height: 600px;
    }
    img {
        width: 15vw;
        height: auto;
        position: absolute;
    }
    .left-accent {
        left: -75px;
        bottom: -75px;
    }
    .right-accent {
        top: 30px;
        right: -10vw;
    }
}