#FAQ {
    display: flex;
    place-content: center;
    margin-bottom: 35px;
    flex-direction: column;
    width: 70%;
    place-self: center;
    margin-top: 50px;
    #FAQmain {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    h2 {
        font-size: 2em;
        font-weight: 600;
        padding-bottom: 50px;
        text-align: center;
    }
}

.section-titles {
    color: black;
    margin-bottom: 4%;
}

.accordion__item {
    display: flex;
    flex-direction: column;
}

.accordion__title {
    padding: 10px 0px;
    cursor: pointer;
    h3 {
        color: black;
        text-align: left;
        font-size: 1em;
        font-weight: 400;
    }
}

.non-expanded {
     ::after {
        content: ' ';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #000;
        clear: both;
        display: inline-block;
    }
}

.expanded {
     ::after {
        content: ' ';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #000;
        clear: both;
        display: inline-block;
    }
}

.accordion__body {
    padding-right: 150px;
    transition: height 0.5s;
    overflow: hidden;
    p {
        padding: 10px 0px;
        color: black;
        text-align: left;
        * {
            color: black;
        }
    }
}

.accordion__body--hidden {
    padding-right: 150px;
    transition: height 0.5s;
    overflow: hidden;
    p {
        padding: 10px 0px;
        color: black;
        text-align: left;
        * {
            color: black;
        }
    }
}
