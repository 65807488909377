#Header {
    width: 100%;
    position: absolute;
    height: 150px;
}

#mlh-trust-badge {
    display: block;
    max-width: 100px;
    min-width: 60px;
    position: absolute;
    right: 50px;
    top: 0px;
    width: 10%;
}

#HeaderInner {
    position: absolute;
    width: 100%;
    height: 100%;
}

#HeaderInner #Logo {
    padding: 15px;
    padding-left: 10vw;
    float: left;
    height: 100%;
}

#Body {
    overflow: hidden;
    width: 100%;
    background: url('../img/bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
}

#Jumbo {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 15%;
    h1 {
        font-size: 6vw;
    }
    h2,
    h3,
    h4 {
        font-size: 1.5vw;
        font-weight: 300;
        margin: 0px;
        margin-top: 0px;
        letter-spacing: 2px;
    }
    .btn-wrapper {
        width:30vw;
        display:grid;
        grid-template-columns: 50% 50%;
        grid-gap:20px;
    }
    .btn-filled {
        height: 50px;
        background-color: #ff4707;
        margin-top: 40px;
        margin-bottom: 150px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        display: flex;
        place-content: center;
        font-weight: 500;
        border-radius: 5px;
        font-size: 1.25em;
        @media screen and (max-width: 1200px) {
            font-size: 1em;
        }
        @media screen and (max-width: 980px) {
            font-size: 1.25em;
        }
        transition: all 0.3s cubic-bezier(0.25,
        0.46,
        0.45,
        0.94);
        @include unselectable();
        div {
            display: flex;
            place-content: center;
            flex-direction: column;
        }
    }
    .btn-ghost {
        height: 50px;
        border: solid 2px #ff4707;
        border-radius: 5px;
        margin-top: 40px;
        margin-bottom: 150px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        display: flex;
        place-content: center;
        font-weight: 500;
        font-size: 1.25em;
        @media screen and (max-width: 1200px) {
            font-size: 1em;
        }
        @media screen and (max-width: 980px) {
            font-size: 1.25em;
        }
        transition: all 0.3s cubic-bezier(0.25,
        0.46,
        0.45,
        0.94);
        @include unselectable();
        div {
            display: flex;
            place-content: center;
            flex-direction: column;
        }
    }
    @media screen and (max-width:980px) {
        height: 100vh;
        box-sizing: border-box;
    }
}

// Overrides for Headers
#RightJumbo {
    width: 33.333%;
}

#LeftJumbo {
    width: 66.666%
}

#UpdateBar {
    display: flex;
    place-content: center;
    place-items: center;
    position: absolute;
    width: 100%;
    height: 100px;
    background-color: rgba(1, 1, 1, 0.8);
    z-index: 100;
}
