.diagonal-first {
    width: 200%;
    height: 500px;
    -webkit-transform: translateY(-20px) translateX(5px) rotate(15deg);
    position: absolute;
    left: -200px;
    margin-top: -24px;
}

#LastYear {
    padding-top: 50vh;
    position: relative;
    background-image: url("../img/last_year.svg");
    background-size: 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
    box-sizing: border-box;
    height: 50vw;
    margin-top: -20vw;
    .ly-title {
        padding-bottom: 50px;
        font-size: 1vw;
    }
    div.first {
        padding: 0px 5%;
        box-sizing: border-box;
        position: absolute;
        bottom: 21vw;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
    .ly-grid {
        display: grid;
        grid-template-columns: auto auto auto auto;
        width: 50%;
        .ly-segment {
            border-right: solid 2px white;
            text-align: center;
            span {
                display: block;
            }
            div {
                display: contents;
                text-align: left;
                span {
                    text-align: center;
                }
            }
        }
    }
    .ly-segment:last-of-type {
        border-right: 0px;
    }
}

.ly-title {
    h2 {
        font-size: 2em;
        text-align: center;
    }
}