.italic {
    font-style: italic;
}

.desk {
    display: block;
}

.mobile {
    display: none;
}

.unselectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

a {
    color: $color-white;
}

a.no-under {
    text-decoration: none;
}

.btn-rounded {
    border-radius: 5px;
}

.btn {
    min-width: 350px;
}

.white-font {
    color: $color-white;
}

.flex-columns {
    flex-direction: column;
}

.float-right {
    float: right;
}