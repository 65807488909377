#Sponsor-logos {
    width: 70%;
    margin: auto;
    h2 {
        text-align: center;
        font-size: 2em;
        font-weight: 600;
        padding-bottom: 50px;
    }
    .active-sponsors {
        $col-width: calc(100% / 6);
        display: grid;
        grid-template: "innovapost innovapost innovapost innovapost innovapost innovapost" ". solace solace solace solace ." "MNP MNP deloitte deloitte RBR RBR" "ADGA ADGA DRDC DRDC EWA EWA" "Phreesia Phreesia CDW CDW splunk splunk" "boc boc signal signal surveymonkey surveymonkey" "kanatanorthbia kanatanorthbia ipsg ipsg cengen cengen" "wolfram wolfram balsamiq balsamiq onepassword onepassword" "digitalocean digitalocean stickermule stickermule voiceflow voiceflow" ". lspark lspark bus bus ." "raon raon bridgehead bridgehead sparklingice sparklingice" / $col-width $col-width $col-width $col-width $col-width $col-width;
        margin-bottom: 100px;
        .sponsor {
            text-align: center;
            display: flex;
            place-items: center;
            place-content: center;
            margin-bottom: 20px;
            a {
                width: 100%;
            }
            img {
                width: 100%;
                height: auto;
                margin-bottom: 20px;
            }
        }
        .sponsor.in-kind img {
            width: 50%;
        }
        .innovapost {
            grid-area: innovapost;
            img {
                width: 100%;
            }
        }
        .solace {
            grid-area: solace;
            img {
                width: 70%;
            }
        }
        .MNP {
            grid-area: MNP;
            img {
                width: 50%;
            }
        }
        .deloitte {
            grid-area: deloitte;
            img {
                width: 100%;
            }
        }
        .RBR {
            grid-area: RBR;
            img {
                filter: invert(100%);
                width: 45%;
            }
        }
        .ADGA {
            grid-area: ADGA;
            img {
                width: 75%;
            }
        }
        .DRDC {
            grid-area: DRDC;
            img {
                width: 100%;
            }
        }
        .EWA {
            grid-area: EWA;
            img {
                width: 50%;
            }
        }
        .CDW {
            grid-area: CDW;
            img {
                width: 60%;
            }
        }
        .Phreesia {
            grid-area: Phreesia;
            img {
                width: 85%;
            }
        }
        .splunk {
            grid-area: splunk;
            img {
                width: 85%;
            }
        }
        .signal {
            grid-area: signal;
            img {
                width: 32%;
            }
        }
        .kanatanorthbia {
            grid-area: kanatanorthbia;
            img {
                width: 40%;
            }
        }
        .ipsg {
            grid-area: ipsg;
            img {
                width: 70%;
            }
        }
        .wolfram {
            grid-area: wolfram;
        }
        .balsamiq {
            grid-area: balsamiq;
        }
        .onepassword {
            grid-area: onepassword;
            img {
                width: 55% !important;
            }
        }
        .digitalocean {
            grid-area: digitalocean;
            img {
                width: 55% !important;
            }
        }
        .lspark {
            grid-area: lspark;
            img {
                width: 40% !important;
            }
        }
        .boc {
            grid-area: boc;
            img {
                width: 75% !important;
            }
        }
        .surveymonkey {
            grid-area: surveymonkey;
            img {
                width: 90% !important;
            }
        }
        .cengen {
            grid-area: cengen;
            img {
                width: 50% !important;
            }
        }
        .voiceflow {
            grid-area: voiceflow;
        }
        .stickermule {
            grid-area: stickermule;
            img {
                width: 55% !important;
            }
        }
        .raon {
            grid-area: raon;
            img {
                width: 16% !important;
            }
        }
        .sparklingice {
            grid-area: sparklingice;
            img {
                width: 25% !important;
            }
        }
        .bridgehead {
            grid-area: bridgehead;
            img {
                width: 55% !important;
            }
        }
        .bus {
            grid-area: bus;
        }
    }
}
