@media screen and (max-width: 540px) {
    #mlh-trust-badge {
        display: none;
    }
    #About {
        .accent {
            transform: rotate(90deg) translateX(-10px);
            height: 100vw;
            width: auto;
        }
        .accent img {
            height: 100vw;
            width: auto;
        }
        h2 {
            font-size: 2em;
        }
    }
    #HackerNav {
        z-index: 10;
    }
    #HackerSegment {
        display: flex;
        flex-direction: column;
        #HackerBody {
            flex: 1;
            display: flex;
            place-content: center;
            justify-content: center;
            align-content: center;
            .submit-verify-box {
                width: 100%;
            }
            #Dashboard {
                height: auto;
            }
            .DashRight {
                height: auto;
                padding: 10%;
            }
        }
    }
    #Jumbo {
        background: url('../img/mobile_bg.png');
        background-size: cover;
        background-position: center;
        height: 100vh;
        box-sizing: border-box;
        padding-top: 70vw;
        margin-bottom: 75px;
        text-align: center;
        .btn-wrapper {
            width: 100%;
            grid-template-columns: 100%;
            margin-top: 10vh;
            div {
                width: 100%;
                margin: 0px;
            }
        }
        .btn {
            width: 90vw;
            margin: 0px;
            div {
                font-size: 1em;
            }
        }
    }
    h1 {
        font-size: 13vw;
    }
    h2,
    h3,
    h4 {
        font-weight: bold;
        font-size: 5vw;
    }
    #CallToAction {
        display: flex;
        flex-direction: column;
    }
    #ComingSoon {
        place-self: center;
    }
    #HeaderInner {
        #Hamburger {
            color: $color-white;
            font-size: 5em;
            padding-top: 20px;
            cursor: pointer;
        }
        #Nav {
            margin-left: 0px;
            margin-right: 10vw;
            float: right;
            z-index: 2;
            .nav-list {
                transition: all 0.5s ease-in-out;
                left: -100vw;
                top: 0px;
                padding: 0px;
                padding-top: 150px;
                display: initial;
                position: absolute;
                height: 100vh;
                background: rgba(0, 0, 0, 0.9);
                span {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    margin: 20px;
                    font-size: 2.5em;
                    font-weight: bolder;
                    cursor: pointer;
                }
                li {
                    display: block;
                    height: auto;
                    float: none;
                    width: 100%;
                    text-align: center;
                }
                a {
                    text-decoration: none;
                    font-size: 3em;
                    font-weight: bold;
                }
            }
        }
        #Nav.toggle {
            position: fixed;
            width: 100%;
            top: 0px;
            margin: 0px;
            z-index: 1;
            .nav-list-collapse {
                display: none;
            }
            .nav-list {
                left: 0px;
                transform: translateX(0);
            }
            #Exit {
                display: initial;
                color: white;
                padding: 25px;
            }
        }
        #EventsWrapper {
            width: 100vw;
        }
    }
    #LastYear {
        margin-top: 5vh;
        background-color: $color-peach;
        height: auto;
        background-position: center center;
        padding-bottom: 20vw;
        padding-top: 0px;
        div.first {
            padding: 0px 5%;
            box-sizing: border-box;
            position: relative;
            bottom: auto;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }
        .ly-grid {
            width: 100%;
        }
        .ly-title {
            margin-top: 2vh;
            font-size: 3vw;
            padding-bottom: 5vh;
        }
    }
    .nav-list-collapse {
        display: inline;
        li {
            width: 100%;
            float: right;
            text-align: center;
            height: 100%;
            display: flex;
            place-content: center;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
        }
        a {
            color: white;
            text-decoration: none;
            font-size: 2.5em;
        }
    }
    #HackingTimer {
        grid-template-areas: 'title title' 'segment1 segment2' 'segment3 segment4';
    }
    #Timer>div,
    #LastYear>div {
        width: 100%;
    }
    #LastYear .ly-grid {
        display: grid;
        row-gap: 10px;
    }
    #Timer>div:first-of-type {
        display: none;
    }
    #LastYear>div.first {
        padding: 10px;
        padding-bottom: 100px;
    }
    #Sponsors {
        background-color: $color-orange;
    }
    #Sponsor-logos {
        width: 60%;
        .active-sponsors {
            display: block;
            .track img {
                width: 100%
            }
            .gold img {
                width: 90%
            }
            .silver img {
                width: 55%
            }
            .bronze img {
                width: 70%;
            }
            .in-kind img {
                width: 60% !important;
            }
            .deloitte img {
                width: 90% !important;
            }
            .ADGA img {
                width: 90% !important;
            }
            .Phreesia img {
                width: 90% !important;
            }
            .splunk img {
                width: 70% !important;
            }
            .CDW img {
                width: 70% !important;
            }
            .DRDC img {
                width: 90% !important;
            }
            .EWA img {
                width: 65% !important;
            }
            .signal img {
                width: 40% !important;
            }
            .raon img {
                width: 40% !important;
            }
            .sparklingice img {
                width: 40% !important;
            }
            .bridgehead img {
                width: 75% !important;
            }
        }
    }
    #partners {
        width: 60%;
        .active-partners {
            display: block;
            .partner {
                padding: 25px;
            }
            .mlh>img {
                width: 53% !important;
            }
            .github>img {
                width: 60% !important;
            }
            .uottawa>img {
                width: 65% !important;
            }
        }
    }
    .accordion__title {
        width: 110%;
        padding: 10px;
    }
    .desk {
        display: none;
    }
    .mobile {
        display: block;
    }
    .image-banner {
        height: 75vw;
        box-shadow: 5px 0px 10px grey;
        background-size: cover;
        background-position: center;
        margin-bottom: 0px;
    }
    #Intro {
        width: 90%;
    }
    #Intro h3 {
        font-size: 1.75em;
        margin-bottom: 25px;
    }
    #HackingTimer>.time-title>h2 {
        color: black;
        text-align: center;
        font-size: 3em;
        width: 100%;
    }
    .half-width {
        width: 100%;
    }
    #Intro>div {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    #Intro>div:first-of-type {
        padding-top: 10px;
        padding-bottom: 0px;
    }
    #Sponsors {
        background-image: url(../img/sponsors.svg);
        background-size: 140%;
        background-position: left -9vw;
        width: 100%;
        margin-bottom: 100px;
        padding-bottom: 0px;
        .container {
            padding: 0px;
        }
        .row {
            margin: 0px 15px;
        }
        h2 {
            text-align: center;
            font-size: 1em;
            margin-top: 35vw;
            font-weight: bold;
        }
        .col-xs-12 {
            padding: 0px;
        }
        .btn-sponsor {
            margin-right: auto;
            margin-left: auto;
            width: 90vw;
            padding: 10px;
            margin-top: 5vw;
            margin-bottom: 10vw;
            text-align: center;
        }
    }
    .active-sponsors {
        grid-template-columns: auto;
        grid-template-areas: 'solace' 'wolfram' 'balsamiq' 'onepassword' 'innovapost';
        width: 100%;
    }
    .sponsor {
        padding: 25px 25px;
    }
    .onepassword {
        padding-bottom: 50px;
    }
    .wolfram {
        padding-bottom: 0px;
    }
    .balsamiq {
        padding-top: 0px;
    }
    .btn-sponsor {
        margin-bottom: 50px;
    }
    .active-sponsors {
        margin-bottom: 50px;
    }
    #Intro>div {
        padding: 0px;
    }
    #Jumbo h1 {
        font-size: 13vw;
    }
    #CallToAction h2,
    #CallToAction h3 {
        font-weight: bold;
        font-size: 5vw;
    }
    #ThankYou {
        font-size: 5vw !important;
        font-weight: bold !important;
    }
    #FAQ {
        width: 90%;
        #FAQmain {
            grid-template-columns: 100%;
        }
        .accordion__title {
            max-width: 100%;
            box-sizing: border-box;
        }
        .accordion__body {
            margin: 0px 20px;
            padding-right: 0px;
        }
    }
    #Footer {
        margin: 0px;
        background: $color-purple;
        div {
            padding-right: 0px;
            padding-left: 0px;
            padding-bottom: 0px;
        }
    }
    $sizes: (10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    100,
    150,
    200);
    $directions: ('top',
    'bottom',
    'left',
    'right');
    @each $size in $sizes {
        @each $direction in $directions {
            .pad-#{$direction}-#{$size}-mobile {
                padding-#{$direction}: #{$size}px;
            }
        }
    }
    $directions: ('top',
    'bottom',
    'left',
    'right');
    @each $size in $sizes {
        @each $direction in $directions {
            .margin-#{$direction}-#{$size}-mobile {
                margin-#{$direction}: #{$size}px;
            }
        }
    }
}