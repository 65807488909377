#HeaderInner {
    position: absolute;
    width: 100%;
    height: 100%;
    #Logo {
        padding: 15px;
        padding-left: 15px;
        padding-left: 10vw;
        float: left;
        height: 100%;
        img {
            height: 70%;
            width: auto;
        }
    }
    #Nav {
        width: 25vw;
        height: 70%;
        margin: 15px;
        margin-right: 15px;
        float: right;
        margin-right: 30vw;
        #Exit {
            display: none;
        }
    }
}

div[class*=" nav-list-*"] {
    li {
        text-align: center;
        height: 100%;
        display: flex;
        place-content: center;
        line-height: 70px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    }
    a {
        text-decoration: none;
        font-size: 1.25em;
        font-weight: bold;
    }
}

.nav-list {
    display: grid;
    grid-template-columns: auto auto auto;
    list-style: none;
    height: 100%;
    width: 100%;
    padding: 0;
    li {
        text-align: center;
        height: 100%;
        display: flex;
        place-content: center;
        line-height: 70px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    }
    a {
        text-decoration: none;
        font-size: 1.25em;
        font-weight: bold;
    }
}

.nav-list-collapse {
    display: none;
    height: 100%;
}