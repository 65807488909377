#HackerSegment {
    display: grid;
    grid-template-columns: 20% 80%;
    height: 100vh;
    @media screen and (max-width: 980px) {
        display: block;
    }
    #HackerNav {
        height: 100vh;
        height: 100%;
        background: $color-blue;
        background-image: url("../img/Web.png");
        background-repeat: no-repeat;
        background-position: bottom;
        @media screen and (max-width: 980px) {
            width: 100vw;
            height: 100px;
        }
        .HackerHeader {
            height: 300px;
            width: 100%;
            background-color: $color-blue;
            margin-bottom: 25px;
            display: flex;
            place-content: center;
            place-items: center;
            @media screen and (max-width: 980px) {
                width: 100vw;
                height: 0px;
            }
            img {
                width: 50%;
                height: auto;
                @media screen and (max-width: 980px) {
                    display: none;
                }
            }
        }
        .expanded-nav-border {
            background-color: $color-blue;
            z-index: 100;
        }
        ul {
            li {
                width: 100%;
                text-align: center;
                font-family: Harabara;
                letter-spacing: 2px;
                font-size: 1.75em;
                display: flex;
                place-items: center;
                place-content: center;
                border-top: solid 1px $color-blue;
                border-bottom: solid 1px $color-blue;
                background: $color-blue;
                transition: background 0.2s ease-in-out;
                cursor: pointer;
                z-index: 10;
                a {
                    padding-bottom: 20px;
                    padding-top: 20px;
                    width: 100%;
                    &.active,
                    &:hover {
                        color: white;
                    }
                }
                @media screen and (max-width: 980px) {
                    text-align: center;
                }
                &.active,
                &:hover {
                    background-color: rgba(0, 0, 0, .6);
                    color: $color-white;
                    @media screen and (max-width: 980px) {
                        background: $color-blue;
                    }
                }
            }
        }
    }
    #HackerBody {
        height: 100vh;
        overflow-y: scroll;
        place-content: center;
        @media screen and (max-width: 980px) {
            width: 100vw;
            height: auto;
            overflow-y: initial;
        }
        .DashRight {
            height: 100vh;
            width: 100%;
            display: flex;
            place-content: center;
            place-items: center;
        }
        .submit-verify-box {
            border: solid 1px #FF4921;
            border-radius: 15px;
            padding: 87px;
            width: 46%;
            text-align: center;
            font-size: 1.5em;
        }
        .ui.form {
            width: 50vw;
            padding: 100px;
            padding-top: 0px;
            margin: 100px auto;
            left: 0;
            right: 0;
            box-sizing: content-box;
            @media screen and (max-width: 980px) {
                width: 100%;
                padding: 45px;
                box-sizing: border-box;
                margin: 100px 0px;
            }
            h1 {
                font-size: 3em;
                color: $color-purple;
                font-family: "Inter";
                font-weight: bold;
                text-align: center;
                font-style: italic;
            }
        }
        #Dashboard {
            height: 100vh;
            display: flex;
            flex-direction: column;
            place-content: center;
            place-items: center;
            .ui.form {
                width: 50vw;
                padding: 100px;
                margin: 100px auto;
                left: 0;
                right: 0;
                box-sizing: content-box;
            }
            .btn {
                &:hover {
                    color: white;
                }
            }
        }
        #ProfileSection {
            h2 {
                text-align: center;
                color: $color-purple;
                font-size: 3em;
                font-style: italic;
            }
            h4 {
                text-align: center;
            }
        }
    }
}

.ui.form .field>label {
    color: $color-black;
}

.loader-hidden {
    display: none;
}

.loader-active {
    display: block;
}

.ui.input {
    width: 100%;
    height: 50px;
}

.form-container {
    width: 70%;
    float: left;
    padding: 0px 100px;
}

.form-container button {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: #e0e1e2 none;
    background-color: rgb(224, 225, 226);
    background-image: none;
    color: rgba(0, 0, 0, .6);
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0 .25em 0 0;
    padding: .78571429em 1.5em .78571429em;
    text-transform: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: .28571429rem;
    -webkit-box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, .15) inset;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, .15) inset;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: opacity .1s ease, background-color .1s ease, color .1s ease, background .1s ease, -webkit-box-shadow .1s ease;
    transition: opacity .1s ease, background-color .1s ease, color .1s ease, background .1s ease, -webkit-box-shadow .1s ease;
    transition: opacity .1s ease, background-color .1s ease, color .1s ease, box-shadow .1s ease, background .1s ease;
    transition: opacity .1s ease, background-color .1s ease, color .1s ease, box-shadow .1s ease, background .1s ease, -webkit-box-shadow .1s ease;
    will-change: '';
    -webkit-tap-highlight-color: transparent;
    margin-top: 10px;
}

button.ui.submit {
    float: right;
    background: $color-orange!important;
    color: $color-white!important;
}

.ui.buttons:not(.basic):not(.inverted)>.button,
.ui.buttons>.ui.button:not(.basic):not(.inverted) {
    -webkit-box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, .15) inset;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, .15) inset;
}

textarea {
    margin: 0;
    max-width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: .67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    color: rgba(0, 0, 0, .87);
    border-radius: .28571429rem;
    -webkit-transition: border-color .1s ease, -webkit-box-shadow .1s ease;
    transition: border-color .1s ease, -webkit-box-shadow .1s ease;
    transition: box-shadow .1s ease, border-color .1s ease;
    transition: box-shadow .1s ease, border-color .1s ease, -webkit-box-shadow .1s ease;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
}

.form-container>div>div>.field {
    height: 50px;
    margin: 15px 0px;
}

.form-container .field>.field {
    height: 50px;
    margin: 5px 0px;
}

.ui.form .field>.selection.dropdown {
    height: 50px;
}

.ui.form textarea {
    height: 200px;
}

.ui.form input[type="checkbox"],
.ui.form textarea,
.ui.form .field>.selection.dropdown {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.btn {
    width: 350px;
    height: 50px;
    background-color: #ff4707;
    margin-top: 40px;
    margin-bottom: 150px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    display: flex;
    place-content: center;
    font-weight: 500;
    border-radius: 5px;
    @media screen and (max-width: 1200px) {
        font-size: 1em;
    }
    @media screen and (max-width: 980px) {
        font-size: 1.25em;
    }
    transition: all 0.3s cubic-bezier(0.25,
    0.46,
    0.45,
    0.94);
    padding: 25px;
    @include unselectable();
    min-width: 150px;
    color: $color-white;
    @media screen and (max-width: 980px) {
        width: 80%;
    }
    div {
        display: flex;
        place-content: center;
        flex-direction: column;
        color: $color-white;
        &:hover {
            color: $color-white;
        }
    }
}

.form-accent:first-of-type {
    position: absolute;
    right: -5vw;
    top: 20vh;
    width: 7vw;
    @media screen and (max-width: 980px) {
        position: absolute;
        transform: rotateZ(90deg);
        width: auto;
        right: auto;
        top: -22vh;
        height: 40vh;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.form-accent:last-of-type {
    position: absolute;
    left: -7vw;
    bottom: 20vh;
    width: 10vw;
    @media screen and (max-width: 980px) {
        position: absolute;
        transform: rotateZ(90deg);
        width: auto;
        right: auto;
        bottom: -22vh;
        height: 30vh;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -1;
    }
}