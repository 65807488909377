@font-face {
    font-family: "Harabara";
    src: url("../fonts/Harabara.woff") format("woff");
}

* {
    padding: 0px;
    margin: 0px;
    font-family: Inter;
}

.main-font {
    font-family: 'Harabara';
    // Hello World
}

.second-font {
    font-family: Inter, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

html {
    scroll-behavior: smooth;
}

// Layout
@import "mixins/clearfix";
@import "mixins/grid-framework";
@import "mixins/grid";
@import "mixins/breakpoints";
@import "mixins/select";
@import 'variables';
@import 'colors';
@import 'utils';
@import 'nav';
@import 'cards';
@import 'classes';
@import 'grid';
//
// Jumbo Section
//
@import 'jumbo';
//
// About Section
//
@import 'about';
//
// LastYear Section
//
@import 'lastyear';
//
// Sponsors Section
//
@import 'sponsors';
//
// Sponsor Logos Section
//
@import 'sponsorLogos';
//
// Community Partners Section
//
@import 'partners';
//
// Location Section
//
@import 'location';
//
// FAQ Section
//
@import 'faq';
//
// Footer Section
//
@import 'footer';
//
// Hacker Dashboard Styles
//
@import 'dashboard';
// Mobile
@import 'mobile';
