#Footer {
    background: url('../img/footer.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 5vw;
    height: 350px;
    h2,
    p {
        text-align: center;
        color: $color-white;
        margin-bottom: 20px;
    }
    .social-links {
        justify-items: center;
        justify-content: center;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 25px 25px 25px;
        img {
            width: 25px;
        }
    }
}