#About {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    position: relative;
    p {
        margin-top: 25px;
        margin-bottom: 25px;
        line-height: 1.5em;
    }
    h2 {
        font-weight: 700;
        font-size: 2.8em;
    }
    div {
        box-sizing: border-box;
    }
    .row {
        img.top-bump {
            position: relative;
            top: -9vw;
        }
    }
    .row.column {
        flex-direction: column;
    }
}