$color-blue: #424FFD;
$color-dark-blue: #1B24A2;
$color-purple: #341A51;
$color-orange: #FF4921;
$color-peach: #FFA266;
//
$color-black: #000;
$color-white: #FFF;
$color-transparent: rgba(0, 0, 0, 0);
//
$colors: ("blue":$color-blue, "dark-blue":$color-dark-blue, "purple":$color-purple, "orange":$color-orange, "peach":$color-peach, "white": $color-white);
//
@each $name,
$color in $colors {
    .text-#{$name} {
        color: $color;
    }
    .#{$name} {
        background-color: $color;
    }
}