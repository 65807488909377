.carousel-inner>.item>a>img,
.carousel-inner>.item>img,
.img-responsive,
.thumbnail a>img,
.thumbnail>img {
    display: block;
    max-width: 100%;
    height: auto;
}

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.
@if $enable-grid-classes {
    // Single container class with breakpoint max-widths
    .container {
        @include make-container();
        @include make-container-max-widths();
    }
    // 100% wide container at all breakpoints
    .container-fluid {
        @include make-container();
    }
    // Responsive containers that are 100% wide until a breakpoint
    @each $breakpoint,
    $container-max-width in $container-max-widths {
        .container-#{$breakpoint} {
            @extend .container-fluid;
        }
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            %responsive-container-#{$breakpoint} {
                max-width: $container-max-width;
            }
            @each $name,
            $width in $grid-breakpoints {
                @if ($container-max-width > $width or $breakpoint==$name) {
                    .container#{breakpoint-infix($name, $grid-breakpoints)} {
                        @extend %responsive-container-#{$breakpoint};
                    }
                }
            }
        }
    }
}

// Row
//
// Rows contain your columns.
@if $enable-grid-classes {
    .row {
        @include make-row();
    }
    // Remove the negative margin from default .row, then the horizontal padding
    // from all immediate children columns (to prevent runaway style inheritance).
    .no-gutters {
        margin-right: 0;
        margin-left: 0;
        >.col,
        >[class*="col-"] {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

// Columns
//
// Common styles for small and large grid columns
@if $enable-grid-classes {
    @include make-grid-columns();
}